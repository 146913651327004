exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kuopio-js": () => import("./../../../src/pages/kuopio.js" /* webpackChunkName: "component---src-pages-kuopio-js" */),
  "component---src-pages-rovaniemi-js": () => import("./../../../src/pages/rovaniemi.js" /* webpackChunkName: "component---src-pages-rovaniemi-js" */),
  "component---src-pages-suio-ryu-js": () => import("./../../../src/pages/suio-ryu.js" /* webpackChunkName: "component---src-pages-suio-ryu-js" */),
  "component---src-pages-suomen-shibu-js": () => import("./../../../src/pages/suomen-shibu.js" /* webpackChunkName: "component---src-pages-suomen-shibu-js" */),
  "component---src-pages-tapahtumat-js": () => import("./../../../src/pages/tapahtumat.js" /* webpackChunkName: "component---src-pages-tapahtumat-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */)
}

