import { StyleFunctionProps, extendTheme } from "@chakra-ui/react";

export const FONT_FAMILY = `'Noto Sans', Noto Sans JP, Arial, Helvetica, sans-serif`;

export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
    cssVarPrefix: "suioryufi",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      "html, body, #app": {
        height: "100%",
        scrollBehavior: "smooth",
        backgroundColor: "#000",
      },
    }),
  },
  fonts: {
    heading: FONT_FAMILY,
    body: FONT_FAMILY,
  },
});
